// :cow: Cosmose CONFIDENTIAL :iso:
import { Action, createReducer, on } from '@ngrx/store';
import { getTagListFailed, getTagListRequested, getTagListSucceeded } from './select-tag.actions';
import { TagListResponse } from '@par/app/core/model/dictionaries.response';

export const STATE_NAME_SELECT_TAG = 'select-tag';

export interface State {
  response?: TagListResponse;
  responseError: boolean;
  responseLoading: boolean;
}

const initialState: State = {
  response: undefined,
  responseError: false,
  responseLoading: false,
};

const reducer = createReducer(
  initialState,
  on(getTagListRequested, (state) => ({...state, response: undefined, responseLoading: true, responseError: false})),
  on(getTagListSucceeded, (state, {response}) => ({...state, responseLoading: false, response, responseError: false})),
  on(getTagListFailed, (state) => ({...state, responseLoading: false, responseError: true})),
);

export function selectTagReducer(state: State | undefined, action: Action): any {
  return reducer(state, action);
}

